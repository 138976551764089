import MicroModal from "micromodal";

class AdVideoPopup extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.trigger = this.querySelector('.popup-trigger');
        this.settings = {
            blockId: this.dataset.triggerBlockId,
            embedCode: this.dataset.embedCode,
            transcriptUrl: this.dataset.transcriptUrl,
            autoplay: this.dataset.autoplay !== undefined
        };

        this.trigger.addEventListener('click', (e) => {
            this.openPopup(e);
        });
    }

    openPopup(e) {
        e.preventDefault();

        const popup = this.createPopup(this.settings.blockId)
        document.body.appendChild(popup);

        MicroModal.show(`video-popup-${this.settings.blockId}`, {
            disableScroll: true,
            disableFocus: true,
            onClose: (popup) => {
                document.body.removeChild(popup);
            }
        });
    }

    createPopup(blockId) {
        const videoPopup = document.createElement('div');
        videoPopup.id = `video-popup-${blockId}`;
        videoPopup.className = 'video-popup';
        videoPopup.ariaHidden = 'true';
        videoPopup.setAttribute('data-gtm', 'modal: video-popup');

        const overlay = document.createElement('div');
        overlay.className = 'video-popup__overlay';
        overlay.dataset.micromodalClose = '';
        overlay.tabIndex = -1;

        const container = document.createElement('div');
        container.className = 'video-popup__container';
        container.ariaModal = 'true';
        container.role = 'dialog';

        const header = document.createElement('header');
        header.className = 'video-popup__header';

        const closeBtn = document.createElement('button');
        closeBtn.className = 'video-popup__close';
        closeBtn.ariaLabel = 'Close popup';
        closeBtn.dataset.micromodalClose = '';
        closeBtn.textContent = '×';
        closeBtn.setAttribute('data-embed-code', 'close');

        const content = document.createElement('main');
        content.className = 'video-popup__content';

        const video = document.createElement('div');
        video.className = 'content-video';

        const iframe = document.createElement('iframe');
        iframe.width = 640;
        iframe.height = 390;
        iframe.setAttribute('allow', this.settings.autoplay ? 'autoplay; fullscreen' : 'fullscreen');
        iframe.src = `https://www.youtube.com/embed/${this.settings.embedCode}${this.settings.autoplay ? '?autoplay=1' : ''}`;

        video.appendChild(iframe);
        content.appendChild(video);
        header.appendChild(closeBtn);
        container.appendChild(header);
        container.appendChild(content);
        overlay.appendChild(container);
        videoPopup.appendChild(overlay);

        if (this.settings.transcriptUrl) {
            const footer = document.createElement('footer');
            footer.className = 'video-popup__footer';

            const transcriptBtn = document.createElement('a');
            transcriptBtn.href = this.settings.transcriptUrl;
            transcriptBtn.className = 'btn btn-secondary';
            transcriptBtn.textContent = 'See Transcript';

            footer.appendChild(transcriptBtn);
            container.appendChild(footer);
        }

        return videoPopup;
    }
}

export default AdVideoPopup;