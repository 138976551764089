const debounce = (callback, timeout = 300) => {
    let timeoutId = null;

    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback(...args);
        }, timeout);
    };
}

export default debounce;