class AdTabbedContent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.elements = {
            tabList: this.querySelector('[role="tablist"]'),
            tabControls: this.querySelectorAll('[role="tablist"] [role="tab"]'),
            tabs: this.querySelectorAll('[role="tabpanel"]')
        }

        this.focusedTabIndex = 0;

        for (const tabControl of this.elements.tabControls) {
            tabControl.addEventListener('click', (e) => {
                e.preventDefault();
                this.tabControlClicked(tabControl)
            });
        }

        this.elements.tabList.addEventListener("keydown", (e) => {
            if (e.key !== "ArrowRight" && e.key !== "ArrowLeft")
                return;

            this.elements.tabControls[this.focusedTabIndex].setAttribute("tabindex", -1);

            if (e.key === "ArrowRight") {
                this.focusedTabIndex = this.focusedTabIndex + 1 < this.elements.tabControls.length
                    ? this.focusedTabIndex + 1
                    : 0;
            }

            if (e.key === "ArrowLeft") {
                this.focusedTabIndex = this.focusedTabIndex - 1 >= 0
                    ? this.focusedTabIndex - 1
                    : this.elements.tabControls.length - 1;
            }

            this.elements.tabControls[this.focusedTabIndex].setAttribute("tabindex", 0);
            this.elements.tabControls[this.focusedTabIndex].focus();
        });

        if ('subToOverviewCards' in this.dataset) {
            document.addEventListener('ad-overview-degree-cards:clicked', (e) => {
                const tabControl = this.querySelector(`#${e.detail.tabId}`);

                if (tabControl) {
                    this.tabControlClicked(tabControl);
                    tabControl.scrollIntoView();
                }
            });
        }
    }

    tabControlClicked(cntrl) {
        for (const tabControl of this.elements.tabControls) {
            tabControl.ariaSelected = (tabControl.getAttribute('aria-controls') === cntrl.getAttribute('aria-controls')).toString();
        }

        for (const tab of this.elements.tabs) {
            if (tab.id !== cntrl.getAttribute('aria-controls'))
                tab.setAttribute('hidden', '');
            else
                tab.removeAttribute('hidden');
        }
    }
}

export default AdTabbedContent;