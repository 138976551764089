import MicroModal from "micromodal";
import Cookies from 'js-cookie'

class AdPopup extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.settings = {
            blockId: this.id,
            automaticOpenDelay: parseInt(this.dataset.openDelay),
            rememberShowForDays: parseInt(this.dataset.rememberShow)
        }

        const applyPopupSeenCookie = Cookies.get('applyPopupSeen');

        if (!applyPopupSeenCookie)
            setTimeout(() => this.openPopup(), this.settings.automaticOpenDelay * 1000);
    }

    openPopup() {
        const options = {
            secure: true
        };

        if (!Number.isNaN(this.settings.rememberShowForDays) && this.settings.rememberShowForDays > 0)
            options.expires = this.settings.rememberShowForDays;

        Cookies.set('applyPopupSeen', '', options);

        MicroModal.show(`popup-${this.settings.blockId}`, {
            disableScroll: true,
            disableFocus: true
        });
    }
}

export default AdPopup;