class AdLink extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const header = document.querySelector(".js-header");
        const footer = document.querySelector(".js-footer");
        const hero = document.querySelector(".js-hero");
        const link = this.querySelector("a");

        if (header?.contains(this)) {
            link.setAttribute("cta-region", "header");
            return;
        }

        if (footer?.contains(this)) {
            link.setAttribute("cta-region", "footer");
            return;
        }

        if (hero?.contains(this)) {
            link.setAttribute("cta-region", "hero");
            return;
        }

        link.setAttribute("cta-region", "body");
    }
}

export default AdLink