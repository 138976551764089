class AdAccordion extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const children = this.querySelector(".accordion > .accordion__cards")?.children;
        if (!children) {
            return;
        }
        this.cards = [...children];

        for (const card of this.cards) {
            const title = card.querySelector(".accordion-card__title");
            title.addEventListener('click', (e) => this.handleCardClicked(e, card));
        }
    }

    handleCardClicked(event, card) {
        event.preventDefault();

        for (const card of this.cards.filter(x => x !== card)) {
            this.toggleCard(card, false);
        }

        const isExpanded = card.querySelector('.accordion-card__title').ariaExpanded === 'true';
        this.toggleCard(card, !isExpanded);

        var title = this.getTitle();  
        window.dataLayerPush({
            'event': 'e_expandList',
            'action': (!isExpanded ? "open" : "close"),
            'listName': title, // Set to title of block 
            'listItem': card.querySelector('.accordion-card__title').innerText // Set to text of accordion item
        });
    }

    getTitle() {
        var headline = this.querySelector('.accordion__headline');
        if (headline) {
            return headline;
        }
        var parentTabId = this.parentElement.getAttribute('aria-labelledby');
        if (!parentTabId) {
            parentTabId = this.parentElement.dataset.accordionBtn;
        }

        var tab = document.querySelector(`#${parentTabId}`);
        if (tab) {
            return tab.innerText;
        }

        return "";
    }

    toggleCard(card, expanded) {
        const title = card.querySelector('.accordion-card__title');
        const icon = card.querySelector('.fa');
        const content = card.querySelector('.accordion-card__panel');

        title.ariaExpanded = expanded;
        content.ariaHidden = !expanded;
        var action = "close";
        if (expanded) {
            icon.classList.replace('fa-plus', 'fa-minus');
            action = "open";
        }
        else
            icon.classList.replace('fa-minus', 'fa-plus');
    }
}

export default AdAccordion;