import { Swiper } from 'swiper/bundle';
class AdSwiper extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const centered = this.dataset.centered
            ? this.dataset.centered
            : false;

        const slidesPerView = this.dataset.slidesPerView
            ? this.dataset.slidesPerView
            : "auto";

        const next = this.querySelector('.swiper-next');
        const prev = this.querySelector('.swiper-prev');

        const options = {
            // Optional parameters
            slidesPerView: slidesPerView,
            centeredSlides: centered,
            spaceBetween: 24,
            // If we need pagination
            pagination: {
                el: `#${this.id} .swiper-pagination`,
                clickable: true
            }
        };

        if (next != null && prev != null) {
          options.navigation = {
              nextEl: `#${this.id} .swiper-next`,
              prevEl: `#${this.id} .swiper-prev`,
          }
        }

        this.swiper = new Swiper(`#${this.id}`, options);
        this.querySelector('.swiper-pagination').childNodes.forEach((child) => {
            child.setAttribute('data-gtm', 'carousel');
            var index = child.getAttribute('aria-label');
            child.setAttribute('data-click-action', index);
        })
    }
}

export default AdSwiper;